import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, from, of, throwError } from "rxjs";
import {
  catchError,
  filter,
  finalize,
  switchMap,
  take,
} from "rxjs/operators";
import { AuthService } from "../services/auth/auth.service";
import { CommonUtillService } from "../services/utill/common-utill.service";
import { environment } from "src/environments/environment";
import { RemoteConfig } from "../enums/remote-config.enum";
import { LoginRs } from "../interface/response/login-rs";
import { NavController } from "@ionic/angular";

@Injectable()
export class JwtInterceptorV2 implements HttpInterceptor {
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isRefreshingToken: boolean = false;
  isErrorModalOpen: boolean = false;
  
  constructor(
    private authSvc: AuthService,
    private commonSvc: CommonUtillService,
    private navCtrl: NavController
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
      return next.handle(this.addJWTToken(request)).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                return this.handle401Error(error,request, next);
              default:
                return throwError(error);
            }
          } else {
            return throwError(error);
          }
        })
      );
    
  }

  addJWTToken(request: HttpRequest<any>): HttpRequest<any> {
    const authToken = this.authSvc.currentAccessToken;
    if(authToken){
      request=request.clone({
        setHeaders: {
          "x-token": `Bearer ${authToken}`,
        },
      });
      console.log(request);
    }
     return request;
    

    
  }

  handle401Error(error:HttpErrorResponse,req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.tokenSubject.next(null);
      this.isRefreshingToken = true;

      // get new access token
      return from(this.authSvc.getAccount()).pipe(
        switchMap((acc: LoginRs) => {
          if (acc && acc.refreshToken) {
            return this.authSvc.refreshToken(acc.refreshToken).pipe(
              catchError((error) => {
                this.logout();
                return of(null);
              }),
              switchMap((rs: LoginRs) => {
                if (rs.statusCode == "200") {
                  this.tokenSubject.next(rs.accessToken);

                  acc.accessToken = rs.accessToken;
                  acc.refreshToken = rs.refreshToken;

                  this.authSvc.saveAccount(acc);

                  return next.handle(this.addJWTToken(req));
                } else {
                  this.logout();
                  return of(null);
                }
              })
            );
          } else {
            // no account data found, should be logged out
            this.logout();
            return of(null);
          }
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      // Queue other calls while loading a new token
      return this.tokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((_) => {
          return from(this.authSvc.getAccount()).pipe(
            switchMap((acc: LoginRs) => {
              if (acc && acc.accessToken) {
                return next.handle(this.addJWTToken(req));
              } else {
                this.logout();
                return of(null);
              }
            })
          );
        })
      );
    }
  }

  logout(): void {
    this.authSvc.getAccount().then((acc) => {
      if (acc) {
        this.authSvc.logout().then((rs) => {
          if (!this.isErrorModalOpen) {
            this.isErrorModalOpen = true;
            this.commonSvc.showAlert("trxSession.header", "trxSession.msg").then(_=> {
              // intentionally left blank
            }).finally(() => {
              this.isErrorModalOpen = false;
            });
          }
          this.navCtrl.navigateRoot("home-landing");
        });
      } else {
        this.navCtrl.navigateRoot("home-landing");
      }
    });
  }
}
