import { formatDate } from '@angular/common';
import {
  HttpBackend,
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { File } from '@awesome-cordova-plugins/file/ngx';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import * as moment from 'moment';
import { CommonUtillService } from './common-utill.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { NetworkService } from '../network/network.service';
import { BaseRs } from 'src/app/interface/response/base-rs';
import { LangService } from '../lang/lang.service';
import { RemoteConfig } from 'src/app/enums/remote-config.enum';

@Injectable({
  providedIn: 'root',
})
export class HttpClientUtillService {
  isConnected: Boolean;


  constructor(
    private httpClient: HttpClient,
    private httpBackend: HttpBackend,
    private file: File,
    private androidPermissions: AndroidPermissions,
    private commonSvc: CommonUtillService,
    private translateSvc: TranslateService,
    public toastController: ToastController,
    private fileOpener: FileOpener,
    private networkSvc: NetworkService,
    private langSvc: LangService
  ) {
    this.networkSvc.network$.subscribe((x) => (this.isConnected = x));
  }

  put<T>(uri: string, uuid: string, req: object) {
    //console.log(uri);
    if (this.isConnected) {
      const header: HttpHeaders = this.createHeaders('PUT', uuid, req);
      return this.httpClient.put<T>(uri, req, { headers: header });
    } else {
      return this.showErrorNetwork();
    }
  }

  post<T>(uri: string, uuid: string, req: object) {
    //console.log(uri);
    if (this.isConnected) {
      const header: HttpHeaders = this.createHeaders('POST', uuid, req);
      return this.httpClient.post<T>(uri, req, { headers: header });
    } else {
      return this.showErrorNetwork();
    }
  }

  get<T>(uri: string, uuid: string) {
    //console.log(uri);
    if (this.isConnected) {
      const header: HttpHeaders = this.createHeaders('GET', uuid, null);
      return this.httpClient.get<T>(uri, { headers: header });
    } else {
      return this.showErrorNetwork();
    }
  }

  getWithParams<T>(uri: string, params: Map<string, any>, uuid: string) {
    //console.log(uri);
    if (this.isConnected) {
      const header: HttpHeaders = this.createHeaders('GET', uuid, null);
      let httpParams: HttpParams = new HttpParams();
      params.forEach((value: string, key: string) => {
        httpParams = httpParams.set(key, value.toString());
      });
      //console.log(httpParams);
      return this.httpClient.get<T>(uri, { headers: header, params: httpParams });
    } else {
      return this.showErrorNetwork();
    }
  }


  delete<T>(uri: string, uuid: string) {
    if (this.isConnected) {
      const header: HttpHeaders = this.createHeaders('DELETE', uuid, null);
      return this.httpClient.delete<T>(uri, { headers: header });
    } else {
      return this.showErrorNetwork();
    }
  }

  // http backend
  _put<T>(uri: string, uuid: string, req: object) {
    //console.log(uri);
    if (this.isConnected) {
      const _httpClient = new HttpClient(this.httpBackend);
      const header: HttpHeaders = this.createHeaders('PUT', uuid, req);
      return _httpClient.put<T>(uri, req, { headers: header });
    } else {
      return this.showErrorNetwork();
    }
  }

  _post<T>(uri: string, uuid: string, req: object) {
    //console.log(uri);
    if (this.isConnected) {
      const _httpClient = new HttpClient(this.httpBackend);
      const header: HttpHeaders = this.createHeaders('POST', uuid, req);
      return _httpClient.post<T>(uri, req, { headers: header });
    } else {
      return this.showErrorNetwork();
    }
  }

  _get<T>(uri: string, uuid: string) {
    console.log(uri);
    if (this.isConnected) {
      const _httpClient = new HttpClient(this.httpBackend);
      const header: HttpHeaders = this.createHeaders('GET', uuid, null);
      return _httpClient.get<T>(uri, { headers: header });
    } else {
      return this.showErrorNetwork();
    }
  }

  _delete<T>(uri: string, uuid: string) {
    if (this.isConnected) {
      const _httpClient = new HttpClient(this.httpBackend);
      const header: HttpHeaders = this.createHeaders('DELETE', uuid, null);
      return _httpClient.delete<T>(uri, { headers: header });
    } else {
      return this.showErrorNetwork();
    }
  }

  showErrorNetwork(): Observable<any> {
    return new Observable((observer) => {
      // logic to return data
      this.langSvc.getWord('trxHOMELANDING.no-connection').subscribe((msg) => {
        const errorRs: BaseRs = {
          statusCode: '400',
          message: msg,
        };
        observer.next(errorRs);
        observer.complete();
      });
    });
  }
  createHeaders(method: string, uuid: string, req: object): HttpHeaders {
    const curDate = formatDate(new Date(), 'dd-MM-yyyy HH:mm:ss.sss', 'en');
    let headers = new HttpHeaders();
    headers = headers.set(
      'Authorization',
      'Basic ' + btoa(environment.remoteConfig[RemoteConfig.basicAuthKey])
    );
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('x-uuid', uuid);
    headers = headers.set('x-timestamp', curDate);
    const lang = this.translateSvc.currentLang;
    if (lang) {
      headers = headers.set('Accept-Language', this.translateSvc.currentLang);
    }
    const hmac = CryptoJS.HmacSHA512(
      btoa(method) +
      '.' +
      btoa(uuid) +
      '.' +
      btoa(curDate) +
      '.' +
      (req ? btoa(JSON.stringify(req)) : ''),
      environment.remoteConfig[RemoteConfig.hmacSecretKey]
    ).toString(CryptoJS.enc.Hex);
    headers = headers.set('x-signature', btoa(hmac));
    // console.log("method:" + btoa(method));
    // console.log("uuid:" + btoa(uuid));
    // console.log("timestamp:" + btoa(curDate));
    // console.log("body:" + btoa(JSON.stringify(req)));
    // console.log("hmac:" + hmac);
    // console.log("hmac encode:" + btoa(hmac));
    //console.log(headers);
    return headers;
  }

  downloadFile(name: string, uri: string, uuid: string, body?: object) {
    //console.log(uri);
    if (this.commonSvc.isAndroid() && this.commonSvc.getAndroidSDKVer() < 33) {
      this.androidPermissions
        .checkPermission(
          this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
        )
        .then(
          (result) => {
            console.log('Has permission?', result.hasPermission);
            return this.download(name, uri, uuid, body);
          },
          (err) => {
            this.androidPermissions
              .requestPermission(
                this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
              )
              .then(
                (hasPermissions) => {
                  console.log('permission granted?', hasPermissions);
                  if (hasPermissions) {
                    return this.download(name, uri, uuid, body);
                  } else {
                    this.commonSvc.showAlert(
                      'alertH.download',
                      'alertM.download-permission'
                    );
                  }
                }

              );

            throw err;
          }
        );
    } else {
      return this.download(name, uri, uuid, body);
    }
  }

  download(name: string, uri: string, uuid: string, body?: Object) {
    this.commonSvc.showToast('alertM.start-download', null);
    const today = moment().format('MMDDYYYY_hhmmss');
    let tempPercentage = 0;
    let method = 'GET';
    if (body) {
      method = 'POST';
    }

    let httpRequestFunction;

    if (method === 'POST') {
      const headers: HttpHeaders = this.createHeaders(method, uuid, body);
      httpRequestFunction = () => this.httpClient.post(uri, body, {
        headers: headers,
        responseType: 'blob',
        observe: 'events',
        reportProgress: true,
      });
    } else {
      const headers: HttpHeaders = this.createHeaders(method, uuid, null);
      const request = new HttpRequest('GET', uri, {
        headers,
        responseType: 'blob',
        observe: 'events',
        reportProgress: true,
      });
      httpRequestFunction = () => this.httpClient.request(request);
    }

    return httpRequestFunction().subscribe(async (event) => {

      if (event.type === HttpEventType.DownloadProgress) {
        if (event.total === 0) {
          this.commonSvc.showAlert('alertH.download', 'alertM.no-file');
          return;
        }
        // event.loaded = bytes transfered
        // event.total = "Content-Length", set by the server

        if (this.commonSvc.isMobile()) {
          const percentage = (event.loaded / event.total) * 100;
          if (percentage > tempPercentage && percentage < 100) {
            tempPercentage = Math.round(percentage);
            const value: number = tempPercentage;
            this.commonSvc.showProgressNotification(value, 'alertH.download', name);
          }
        }
      }

      if (event.type === HttpEventType.Response) {
        let filename = '';
        if ((event.body as any).size === 0) {
          if (this.commonSvc.isMobile()) {
            this.showNotification('alertM.fail-download', null, 'danger');
          }
          this.commonSvc.showAlert('alertH.download', 'alertM.no-file');
          return;
        }
        if (name !== '') {
          filename = today + "_" + name + '.pdf';
        } else {
          filename = today + "_" + event.headers
            .get('content-disposition')
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            // .replace(/ /g, "_")
            .trim();
        }

        if (this.commonSvc.isMobile()) {
          await this.getDownloadPath().then((downloadPath) => {
            console.log(downloadPath);
            this.file
              .writeFile(downloadPath, filename, event.body as Blob, {
                replace: true,
              })
              .then((value) => {
                console.log(value);
                const messageDone = downloadPath + filename;
                console.log(messageDone);
                this.showNotification('alertM.success-download', messageDone, 'success');
              }).catch(err => {
                console.log(err);
              });

            return event.body as Blob;
          });
        } else {
          const isPreview = event.headers.get('content-preview');
          this.downloadWebBlob(filename, event.body as Blob, isPreview);
          return event.body as Blob;
        }
      }
    });
  }

  downloadWebBlob(fileName: string, blob: Blob, isPreview: string): void {
    if (isPreview == "true") {
      window.open(window.URL.createObjectURL(blob));
    } else {
      if ((window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveBlob(blob, fileName);
      } else {
        const anchor = window.document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
      }
    }
  }

  async getDownloadPath(): Promise<string> {
    if (this.commonSvc.isIos()) {
      return this.file.cacheDirectory;
    } else if (this.commonSvc.isAndroid() && this.commonSvc.getAndroidSDKVer() < 33) {
      await this.androidPermissions
        .checkPermission(
          this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
        )
        .then((result) => {
          if (!result.hasPermission) {
            return this.androidPermissions.requestPermission(
              this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
            );
          }
        });
    }
    return this.file.externalRootDirectory + '/Download/';
  }


  showNotification(prgTitle: string, prgLocation: string, color?: string, type: string = 'application/pdf') {
    let strMessage = '';
    if (this.commonSvc.isAndroid()) {
      strMessage = prgLocation;
    }
    this.langSvc.getWord(prgTitle).subscribe(header => {
      this.commonSvc.showNotification(header, strMessage
        , () => {
          if (prgLocation) {
            this.fileOpener
              .showOpenWithDialog(prgLocation, type)
              .then(() => console.log('File is opened'))
              .catch((e) => console.log('Error opening file', e));
          }
        }
      );
      this.toastController.create({
        header: header,
        message: strMessage,
        duration: 5000,
        color: typeof color !== 'undefined' ? color : 'dark',
        cssClass: 'toast-download',
        position: 'top',
        buttons: [
          {
            text: prgLocation ? 'Open' : 'close',
            role: 'save',
            handler: () => {
              if (prgLocation) {
                this.fileOpener
                  .showOpenWithDialog(prgLocation, type)
                  .then(() => console.log('File is opened'))
                  .catch((e) => console.log('Error opening file', e));
              }
            },
          },
        ],
      }).then(toast => {
        toast.present();
        toast.onDidDismiss().then(role => {
          console.log('onDidDismiss resolved with role', role);
        })
      });

    });
  }

  generateFile(uri: string, uuid: string, req: object) {
    console.log(uri);
    if (this.isConnected) {
      const headers: HttpHeaders = this.createHeaders('POST', uuid, req);
      return this.httpClient.post(uri, req, {
        headers: headers,
        responseType: 'blob',
      });
    } else {
      return this.showErrorNetwork();
    }
  }

}
