import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ExternalRedirectGuard } from './guards/external-redirect.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home-landing',
    pathMatch: 'full',
  },
  {
    path: 'home-landing',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./pages/home-landing/home-landing.module').then(
        (m) => m.HomeLandingPageModule
      ),
  },
  {
    path: 'login',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'policy',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/policy/policy.module').then((m) => m.PolicyPageModule),
  },
  {
    path: 'tabs',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'mail-detail',
    loadChildren: () =>
      import('./pages/messages/mail-detail/mail-detail.module').then(
        (m) => m.MailDetailPageModule
      ),
  },
  {
    path: 'mail',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/messages/mail/mail.module').then((m) => m.MailPageModule),
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'change-password',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
  },
  {
    path: 'forgotpassword',
    loadChildren: () =>
      import('./pages/forgotpassword/forgotpassword.module').then(
        (m) => m.ForgotpasswordPageModule
      ),
  },
  {
    path: 'resetpassword',
    loadChildren: () =>
      import('./pages/resetpassword/resetpassword.module').then(
        (m) => m.ForgototpPageModule
      ),
  },
  {
    path: 'policy-detail/:policyNum',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/policy-detail/policy-detail.module').then(
        (m) => m.PolicyDetailPageModule
      ),
  },
  {
    path: 'payment-history',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/payment-history/payment-history.module').then(
        (m) => m.PaymentHistoryPageModule
      ),
  },
  {
    path: 'products',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/products/products.module').then(
        (m) => m.ProductsPageModule
      ),
  },
  {
    path: 'coming-soon',
    loadChildren: () =>
      import('./pages/coming-soon/coming-soon.module').then(
        (m) => m.ComingSoonPageModule
      ),
  },
  {
    path: 'investment/:policyNum',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/investment/investment.module').then(
        (m) => m.InvestmentPageModule
      ),
  },
  {
    path: 'payment-info',
    loadChildren: () =>
      import('./pages/payment-info/payment-info.module').then(
        (m) => m.PaymentInfoPageModule
      ),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./pages/faq/faq.module').then((m) => m.FAQPageModule),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./pages/contact-us/contact-us.module').then(
        (m) => m.ContactUsPageModule
      ),
  },
  {
    path: 'unit-price',
    loadChildren: () =>
      import('./pages/unit-price/unit-price.module').then(
        (m) => m.UnitPricePageModule
      ),
  },
  {
    path: 'investment-change',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/investment-change/investment-change.module').then(
        (m) => m.InvestmentChangePageModule
      ),
  },
  {
    path: 'account-profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/account-profile/account-profile.module').then(
        (m) => m.AccountProfilePageModule
      ),
  },
  {
    path: 'statement',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/statement/statement.module').then(
        (m) => m.StatementPageModule
      ),
  },
  {
    path: 'document',
    loadChildren: () =>
      import('./pages/document/document.module').then(
        (m) => m.DocumentPageModule
      ),
  },
  {
    path: 'payment',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: 'pembayaran',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: 'workbasket-trx',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/workbasket-trx/workbasket-trx.module').then(
        (m) => m.WorkbasketTrxPageModule
      ),
  },
  {
    path: 'workbasket-detail',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/workbasket-detail/workbasket-detail.module').then(
        (m) => m.WorkbasketDetailPageModule
      ),
  },
  {
    path: 'billing',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/billing/billing.module').then((m) => m.BillingPageModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/admin-tabs/admin-tabs.module').then(
        (m) => m.AdminTabsPageModule
      ),
  },
  {
    path: 'publish-msg',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/publish-msg/publish-msg.module').then(
        (m) => m.PublishMsgPageModule
      ),
  },
  {
    path: 'send-msg',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/send-msg/send-msg.module').then(
        (m) => m.SendMsgPageModule
      ),
  },
  {
    path: 'add-user',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/add-user/add-user.module').then(
        (m) => m.AddUserPageModule
      ),
  },
  {
    path: 'edit-user',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/edit-user/edit-user.module').then(
        (m) => m.EditUserPageModule
      ),
  },
  {
    path: 'new-claim',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/claim/new-claim/new-claim.module').then(
        (m) => m.NewClaimPageModule
      ),
  },
  {
    path: 'benefits-documents',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/claim/benefits-documents/benefits-documents.module').then(
        (m) => m.BenefitsDocumentsPageModule
      ),
  },
  {
    path: 'benefits-list',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/claim/benefits-list/benefits-list.module').then(
        (m) => m.BenefitsListPageModule
      ),
  },
  {
    path: 'claim-date',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/claim/claim-date/claim-date.module').then(
        (m) => m.ClaimDatePageModule
      ),
  },
  {
    path: 'benefits-disclaimer',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/claim/benefits-disclaimer/benefits-disclaimer.module'
      ).then((m) => m.BenefitsDisclaimerPageModule),
  },
  {
    path: 'claim-history',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/claim/claim-history/claim-history.module').then(
        (m) => m.ClaimHistoryPageModule
      ),
  },
  {
    path: 'claim-history-detail',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/claim/claim-history-detail/claim-history-detail.module'
      ).then((m) => m.ClaimHistoryDetailPageModule),
  },
  {
    path: 'register-user',
    loadChildren: () =>
      import('./pages/register-user/register-user.module').then(
        (m) => m.RegisterUserPageModule
      ),
  },
  {
    path: 'otp',
    loadChildren: () =>
      import('./pages/otp/otp.module').then((m) => m.OTPPageModule),
  },
  {
    path: 'send-claim',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/claim/send-claim/send-claim.module').then(
        (m) => m.SendClaimPageModule
      ),
  },
  {
    path: 'OTL_login',
    canActivate: [ExternalRedirectGuard],
    component: ExternalRedirectGuard,
    data: {
      url: 'https://connector.panindai-ichilife.co.id/OTL_login',
      // url: 'https://connect-business-services-sit.dev.paninlife.com/OTL_login'
    },
  },
  {
    path: 'disclaimer',
   // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/disclaimer/disclaimer.module').then(
        (m) => m.DisclaimerPageModule
      ),
  },
  {
    path: 'admin-html',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/admin-html/admin-html.module').then(
        (m) => m.AdminHTMLPageModule
      ),
  },
  {
    path: 'admin-files',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/admin-files/admin-files.module').then(
        (m) => m.AdminFilesPageModule
      ),
  },
  {
    path: 'debt-coi/:policyNum',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/debt-coi/debt-coi.module').then(
        (m) => m.DebtCOIPageModule
      ),
  },
  {
    path: 'redirect',
    loadChildren: () =>
      import('./pages/redirect/redirect.module').then(
        (m) => m.RedirectPageModule
      ),
  },
  {
    path: 'hospital',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/hospital/hospital.module').then(
        (m) => m.HospitalPageModule
      ),
  },
  {
    path: 'admin-hospital',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/admin-hospital/admin-hospital.module').then(
        (m) => m.AdminHospitalPageModule
      ),
  },
  {
    path: 'change-policy-address/:policyNum',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/change-policy-address/change-policy-address.module').then(
        (m) => m.ChangeAddressAddPageModule
      ),
  },
  {
    path: 'single-top-up/:policyNum',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/single-top-up/single-top-up.module').then(m => m.SingleTopUpPageModule)
  },
  {
    path: 'method-payment/:type',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/method-payment/method-payment.module').then(m => m.MethodPaymentPagePageModule)
  },
  {
    path: 'change-payment-freq/:policyNum',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/change-payment-freq/change-payment-freq.module').then(m => m.ChangePaymentFreqPageModule)
  },
  {
    path: 'change-payment-method/:policyNum',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/change-payment-method/change-payment-method.module').then(m => m.ChangePaymentMethodPageModule)
  },
  {
    path: 'change-beneficiary/:policyNum',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/beneficiary/change-beneficiary/change-beneficiary.module').then(m => m.ChangeBeneficiaryPageModule)
  },
  {
    path: 'beneficiary-doc/:policyNum',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/beneficiary/beneficiary-doc/beneficiary-doc.module').then(m => m.BeneficiaryDocPageModule)
  },
  {
    path: 'send-beneficiary',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/beneficiary/send-beneficiary/send-beneficiary.module').then(m => m.SendBeneficiaryPageModule)
  },
  {
    path: 'reinstatement-disclaimer/:policyNum',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/reinstatement/reinstatement-disclaimer/reinstatement-disclaimer.module'
      ).then((m) => m.ReinstatementDisclaimerPageModule),
  },
  {
    path: 'reinstatement-form/:policyNum',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/reinstatement/reinstatement-form/reinstatement-form.module').then(
        (m) => m.ReinstatementFormPageModule
      ),
  },
  {
    path: 'upload-docs-field/:type',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/upload-docs/upload-docs-field/upload-docs-field.module').then(
        (m) => m.UploadDocPageModule
      ),
  },
  {
    path: 'upload-docs-loader/:type',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/upload-docs/upload-docs-loader/upload-docs-loader.module').then(m => m.UploadDocsLoaderPageModule)
  },
  {
    path: 'video-tutorial',
    loadChildren: () => import('./pages/video-tutorial/video-tutorial.module').then(m => m.VideoTutorialPageModule)
  },
  {
    path: 'biometric',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/biometric/biometric.module').then(m => m.BiometricPageModule)
  },
  {
    path: 'otllogin',
    loadChildren: () => import('./pages/otllogin/otllogin.module').then(m => m.OTLLoginPageModule)
  },
  {
    path: 'otlcontent/:idOTL',
    loadChildren: () => import('./pages/otlcontent/otlcontent.module').then(m => m.OTLContentPageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenancePageModule)
  },
  {
    path: 'otplink',
    loadChildren: () => import('./pages/otplink/otplink.module').then(m => m.OTPLinkPageModule)
  },
  {
    path: 'repricing',
    loadChildren: () => import('./pages/repricing/repricing/repricing.module').then(m => m.RepricingPageModule)
  },
  {
    path: 'otlre',
    loadChildren: () => import('./pages/otlreprice/otlreprice.module').then(m => m.OTLRepricePageModule)
  },
  {
    path: 'admin-videos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/admin-videos/admin-videos.module').then(m => m.AdminVideosPageModule)
  },
  {
    path: 'klik-dokter',
    data: { policyNum: '' },
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/klik-dokter/klik-dokter.module').then(m => m.KlikDokterPageModule)
  },
  {
    path: 'choose-policy',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/klik-dokter/choose-policy/choose-policy.module').then(m => m.ChoosePolicyPageModule)
  },
  {
    path: 'admin-banner',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/admin-banner/admin-banner.module').then( m => m.AdminBannerPageModule)
  },
  {
    path: 'repricing-promotion',
    loadChildren: () => import('./pages/repricing/repricing-promotion/repricing-promotion.module').then( m => m.RepricingPromotionPageModule)
  },
  {
    path: 'repricing-conversion',
    loadChildren: () => import('./pages/repricing/repricing-conversion/repricing-conversion.module').then( m => m.RepricingConversionPageModule)
  },
  {
    path: 'repricing-landing',
    loadChildren: () => import('./pages/repricing/repricing-landing/repricing-landing.module').then( m => m.RepricingLandingPageModule)
  },
  {
    path: 'repricing-product-benefit',
    loadChildren: () => import('./pages/repricing/repricing-product-benefit/repricing-product-benefit.module').then( m => m.RepricingProductBenefitPageModule)
  },
  {
    path: 'repricing-coversion-summary',
    loadChildren: () => import('./pages/repricing/repricing-conversion-summary/repricing-conversion-summary.module').then( m => m.RepricingCoversionSummaryPageModule)
  },
  {
    path: 'conversion-letter',
    loadChildren: () => import('./pages/repricing/conversion-letter/conversion-letter.module').then( m => m.ConversionLetterPageModule)
  },
  {
    path: 'conversion-policies',
    loadChildren: () => import('./pages/conversion-policies/conversion-policies.module').then( m => m.ConversionPoliciesPageModule)
  }







];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
