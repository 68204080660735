import { Injectable } from '@angular/core';
import { MessagesRs } from 'src/app/interface/response/messages-rs';
import { environment } from 'src/environments/environment';
import { CommonUtillService } from '../utill/common-utill.service';
import { HttpClientUtillService } from '../utill/http-client-utill.service';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseRs } from 'src/app/interface/response/base-rs';
import { SendMsgRq } from 'src/app/interface/request/send-msg-rq';
import { SendBroadcastRq } from 'src/app/interface/request/send-broadcast-rq';
import { MessageRs } from 'src/app/interface/response/message-rs';
import { RemoteConfig } from 'src/app/enums/remote-config.enum';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(
    private commonSvc: CommonUtillService,
    private httpClientSvc: HttpClientUtillService,
    private storage: Storage
  ) { }

  newNotif$ = new BehaviorSubject<boolean>(null);


  pokeNewNotif(newNotif: boolean): void {
    this.saveNewNotif(newNotif);
    this.newNotif$.next(newNotif);
  }

  getMessages(username: string, page: number): Observable<MessagesRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.get<MessagesRs>(
      this.commonSvc.formatString(
        environment.remoteConfig[RemoteConfig.uriGetMessages],
        username,
        page.toString()
      ),
      uid
    );
  }

  checkNewNotif(userCode: string) {
    const uid = this.commonSvc.getDeviceUUID();
    this.httpClientSvc.get<MessagesRs>(
      this.commonSvc.formatString(environment.remoteConfig[RemoteConfig.uriGetCheckNewNotif], userCode), uid).subscribe(rs => {
        if (rs.statusCode == "200") {
          this.getReadNotif().then((notif) => {
            if (!notif) {
              this.pokeNewNotif(true);
            } else if(notif && rs.messages){
              rs.messages.forEach(obj => { 
                if (!notif.includes(obj.idMessage)) {
                  this.pokeNewNotif(true);
                  return;
                }
              });
            }
          });
        }
      });
  }

  getMsgDetail(idMsg: number):Observable<MessageRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.get<MessageRs>(
      this.commonSvc.formatString(
        environment.remoteConfig[RemoteConfig.uriGetMsgDetail],
        idMsg.toString()
      ),
      uid
    );
  }


  getBroadcast(page: number): Observable<MessagesRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.get<MessagesRs>(
      this.commonSvc.formatString(environment.remoteConfig[RemoteConfig.uriGetBroadcast], page.toString()),
      uid
    );
  }

  openBroadcast(idMessage: number) {
    return new Promise((resolve) => {
      this.getReadNotif().then((notif) => {
        if (!notif) {
          notif = [idMessage];
        } else if (!notif.includes(idMessage)) {
          notif.push(idMessage);
        }
        this.saveNotif(notif);
        resolve(true);
      });
    });
  }

  openMsg(idMessage: number): Observable<BaseRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.put<BaseRs>(
      this.commonSvc.formatString(
        environment.remoteConfig[RemoteConfig.uriPutOpenMsg],
        idMessage.toString()
      ),
      uid,
      null
    );
  }

  sendMsg(req: SendMsgRq): Observable<BaseRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.post<BaseRs>(
      environment.remoteConfig[RemoteConfig.uriPostSendMsg],
      uid,
      req
    );
  }

  sendBroadcast(req: SendBroadcastRq): Observable<BaseRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.post<BaseRs>(
      environment.remoteConfig[RemoteConfig.uriPostSendBroadcast],
      uid,
      req
    );
  }

  saveNewNotif(newNotif: boolean) {
    return this.storage.set('NEWNOTIF', newNotif);
  }

  getNewNotif() {
    return this.storage.get('NEWNOTIF');
  }

  saveNotif(notif: number[]) {
    return this.storage.set('NOTIF', notif);
  }

  getReadNotif() {
    return this.storage.get('NOTIF');
  }

  saveUpload(upload: string) {
    return this.storage.set('UPLOAD', upload);
  }

  geteUpload() {
    return this.storage.get('UPLOAD');
  }
}
