import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptionUtillService {
  constructor() {}

  encryptPassword(password: string, key: string) {
    let sha = this.encryptSHA512(password, key).toString();
    let aes = this.encryptAES(sha, key);
    let md5 = this.getMD5(aes);
    return md5;
  }

  encryptSHA512(password: string, key: string) {
    let hmac = CryptoJS.HmacSHA512(password, key);
    return hmac;
  }

  getMD5(key: string) {
    let md5 = CryptoJS.MD5(key).toString();
    return md5;
  }

  encryptAES(message: string, key: string) {
    let aes = CryptoJS.AES.encrypt(message, key).toString();
    return aes;
  }
}
