import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternalRedirectGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let url: string = route.data.url || route.params.url
      if (route.queryParams) {
        const queryString = Object.entries(route.queryParams)
            .map(entry => entry.join('='))
            .join('&');
        if (queryString) {
            url += `?${queryString}`;
        }
    }
    window.location.href = url;
    return true;
  }
  
}
