import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonUtillService } from '../utill/common-utill.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  network$ = new BehaviorSubject<boolean>(null);

  constructor(
    private commonSvc: CommonUtillService,
    private network: Network
  ) {
    this.network$.next(true);
  }

  checkInet() {
      if (this.commonSvc.isMobile()) {
        this.network.onDisconnect().subscribe(() => {
          this.commonSvc.showToast('', 'trxHOMELANDING.no-connection');
          this.network$.next(false);
        });
        this.network.onConnect().subscribe(() => {
          this.commonSvc.showToast('', 'trxHOMELANDING.new-connection');
          this.network$.next(true);
        });
        if (this.network.type === this.network.Connection.NONE) {
          this.commonSvc.showToast('', 'trxHOMELANDING.no-connection');
          this.network$.next(false);
        } else {
          this.network$.next(true);
        }
      }
  }

}
