import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/components/layout/header/header.component';
import { IonicModule } from '@ionic/angular';
import { SharedDirectiveModule } from '../shared-directive/shared-directive.module';



@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    IonicModule,
    SharedDirectiveModule
  ],
  exports: [HeaderComponent,SharedDirectiveModule],
  entryComponents: [HeaderComponent]
})
export class HeaderModule { }
