import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RemoteConfig } from '../enums/remote-config.enum';
import * as stacktrace from 'stacktrace-js';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {
    isMaintenance: boolean = false;

    constructor(
        private firebase: FirebaseX,
        private router: Router
    ) {
        this.isMaintenance = environment.remoteConfig[RemoteConfig.isMaintenance];
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // redirect to maintenance screen
        if (this.isMaintenance) {
            this.router.navigate(['maintenance']);
        }
        return next.handle(req).pipe(
            catchError((error) => {
                return throwError(error);
            })
        );
    }

}


