import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckPermissionDirective } from 'src/app/directives/check-permission/check-permission.directive';
import { CheckRoleDirective } from 'src/app/directives/check-role/check-role.directive';



@NgModule({
  declarations: [CheckRoleDirective,CheckPermissionDirective],
  imports: [
    CommonModule
  ],
  exports: [
    CheckRoleDirective,
    CheckPermissionDirective
  ]
})
export class SharedDirectiveModule { }
