
import { AlertController, IonRouterOutlet, Platform } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { LangService } from './services/lang/lang.service';
import { CheckVerService } from './services/checkver/check-ver.service';
import { CommonUtillService } from './services/utill/common-utill.service';
import { AuthService } from './services/auth/auth.service';
import { FireabasexService } from './services/firebasex/fireabasex.service';
import { IdleService } from './services/idle/idle.service';
import { NetworkService } from './services/network/network.service';
import { environment } from 'src/environments/environment';
import { RemoteConfig } from './enums/remote-config.enum';
import { Component, ViewChild } from '@angular/core';
import { register } from 'swiper/element/bundle';
// declare var Countly: any

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  navigate: any;
  isShowBadge: boolean = false;
  isMaintenance: boolean = false;
  isShowExitConfirmModalOpen: boolean = false;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private router: Router,
    private langSvc: LangService,
    private alertCtrl: AlertController,
    private checkVerSvc: CheckVerService,
    private authSvc: AuthService,
    private commonSvc: CommonUtillService,
    private firebaseXService: FireabasexService,
    private idleSvc: IdleService,
    private networkSvc: NetworkService
  ) {
    register();
    this.isShowBadge = !environment.production;
    this.initializeApp();
    this.isMaintenance = environment.remoteConfig[RemoteConfig.isMaintenance];
  }

  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  showExitConfirm() {
    this.isShowExitConfirmModalOpen = true;
    this.langSvc
      .getWords([
        'trxExit.header',
        'trxExit.message',
        'trxExit.stay',
        'trxExit.exit',
      ])
      .subscribe((words) => {
        this.alertCtrl.create({
            header: words['trxExit.header'],
            message: words['trxExit.message'],
            backdropDismiss: false,
            buttons: [
              {
                text: words['trxExit.stay'],
                role: 'cancel',
                handler: () => {
                  this.isShowExitConfirmModalOpen = false;
                },
              },
              {
                text: words['trxExit.exit'],
                handler: () => {
                  this.isShowExitConfirmModalOpen = false;
                  this.authSvc.getAccount().then((account) => {
                    if (account) {
                      this.authSvc.logout().then(() => {
                        navigator['app'].exitApp();
                      });
                    } else {
                      navigator['app'].exitApp();
                    }
                  });
                },
              },
            ],
          })
          .then((alert) => {
            alert.present();
          });
      });
  }

  initializeApp() {
    (window as any).skipLocalNotificationReady = true;
    this.langSvc.initDefaultLang().then((res) => {
      this.platform.ready().then(() => {
        this.networkSvc.checkInet();
        this.firebaseXService.setUpFirebase();
        this.idleSvc.init();
        if (this.commonSvc.isMobile()) {
          (navigator as any).splashscreen.show();
          // Countly.init("https://apis-dev.panindai-ichilife.co.id/countly", "04c5c0e2abf6e64572297f1bf3c6ccb2", "");
          this.statusBar.overlaysWebView(false);
          this.statusBar.backgroundColorByHexString('#0075BA');
          this.checkVerSvc.checkNewVer();
          (navigator as any).splashscreen.hide();
        }
      });
    });

    this.platform.backButton.subscribeWithPriority(10, () => {
      if (!this.routerOutlet.canGoBack() && !this.isShowExitConfirmModalOpen) {
        this.showExitConfirm();
      } else {
        this.routerOutlet.pop();
      }
    });

    this.platform.pause.subscribe(() => {
      // if (this.commonSvc.isMobile()) {
      //   // Countly.stop();
      // }
    });

    this.platform.resume.subscribe(() => {
      // if (this.commonSvc.isMobile()) {
      //   // Countly.start();
      //   this.checkVerSvc.checkNewVer();
      // }
    });
    if (this.commonSvc.isMobile()) {
      this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationStart) {
        }

        if (ev instanceof NavigationEnd) {
          // Hide loading indicator
          const events = { key: ev.url, count: 1 };
         // console.log(ev.url);
          this.firebaseXService.setScreenName(ev.url);
        }

        if (ev instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.error(ev.error);
        }
      });
    }
  }

}
