import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { AppErrorHandler } from './guards/app-error-handler';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { DatePipe } from '@angular/common';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { mdTransitionAnimation } from '@ionic/core';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { FireabasexService } from './services/firebasex/fireabasex.service';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { RecaptchaModule } from 'ng-recaptcha';
import { MaintenancePageModule } from './pages/maintenance/maintenance.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { JwtInterceptorV2 } from './interceptors/jwt-interceptor-v2.interceptor';
import { CustomUrlSerializer } from './pipes/custom-url-serializer';
import { HttpConfigInterceptor } from './interceptors/http-config-interceptor';
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever/ngx';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';



export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${new Date().getTime()}`);
}

export function initializeApp(appInitService: FireabasexService) {
  return () => appInitService.setRemoteConfig()
}


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    RecaptchaModule,
    NgIdleKeepaliveModule.forRoot(),
    IonicModule.forRoot({
      mode: 'md',
      hardwareBackButton: true,
      navAnimation: mdTransitionAnimation,
      innerHTMLTemplatesEnabled: true
    }),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    TourMatMenuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LanguageLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    MaintenancePageModule,
    MatTableModule,
    MatSortModule
  ],
  providers: [
    StatusBar,
    SmsRetriever,
    Market,
    Clipboard,
    Device,
    AppVersion,
    Network,
    Keyboard,
    FirebaseX,
    FingerprintAIO,
    FormBuilder,
    ReactiveFormsModule,
    FormsModule,
    DatePipe,
    Camera,
    Chooser,
    File,
    FileOpener,
    LocationAccuracy,
    Geolocation,
    AndroidPermissions,
    LocalNotifications,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorV2,
      multi: true,
    },
    { provide: APP_INITIALIZER, deps: [FireabasexService], useFactory: initializeApp, multi: true },
    { provide: UrlSerializer, useClass: CustomUrlSerializer }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
}
