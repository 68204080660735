import { Injectable } from '@angular/core';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { AlertController, isPlatform, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { MessageService } from '../message/message.service';
import { CommonUtillService } from '../utill/common-utill.service';
import { HttpClientUtillService } from '../utill/http-client-utill.service';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/app';
import "firebase/remote-config";
import '@firebase/messaging';
import { LangService } from '../lang/lang.service';
import { BaseRs } from 'src/app/interface/response/base-rs';
import { FCMTopicRq } from 'src/app/interface/request/fcmtopic-rq';
import { Observable } from 'rxjs';
import { Idle } from '@ng-idle/core';
import { UserDeviceDTO } from 'src/app/interface/dto/user-device-dto';
import { LoginRs } from 'src/app/interface/response/login-rs';
import { DeviceTokenRq } from 'src/app/interface/request/device-token-rq';
import { RemoteConfig } from 'src/app/enums/remote-config.enum';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FireabasexService {
  currentRoute: string = '';
  whitelistUnsupporteFCMRoute: string[] = [
    '/otlre',
    '/repricing',
    '/otp'
  ];

  constructor(
    private firebaseX: FirebaseX,
    private commonSvc: CommonUtillService,
    private httpClientSvc: HttpClientUtillService,
    private alertCtrl: AlertController,
    private messageSvc: MessageService,
    private navCtrl: NavController,
    private storage: Storage,
    private langSvc: LangService,
    private idle: Idle,
    private router: Router,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.currentRoute = this.router.url;
      if (!this.commonSvc.isMobile()
        && !firebase.messaging.isSupported()
        && !this.whitelistUnsupporteFCMRoute.some(whitelist => this.currentRoute.includes(whitelist))) {
          this.showDialogUnsupportFb();
      }
    });
  }

  setUpFirebase() {
    this.setPushNotif();
    this.setRemoteConfig();
  }

  setPushNotif() {
    if (this.commonSvc.isMobile()) {
      this.firebaseX.hasPermission().then((hasPermission) => {
        if (!hasPermission) {
          this.firebaseX.grantPermission().then(() => {
            this.getFCM(0);
          });
        } else {
          this.getFCM(0);
        }
      });
    } else {
      this.getFCM(0);
    }
  }


  setRemoteConfig(): Promise<any> {
    return new Promise((resolve) => {
      if (!firebase.apps.length) {
        firebase.initializeApp(environment.firebaseConfig);
      } else {
        firebase.app(); // if already initialized, use that one
      }
      firebase.remoteConfig().settings.minimumFetchIntervalMillis = 3600000;
      firebase.remoteConfig().fetchAndActivate().then(activated => {
        environment.remoteVer = firebase.remoteConfig().getString(environment.firebaseVer);
        environment.remoteConfig = JSON.parse(firebase.remoteConfig().getString(environment.firebaseEnv));
        resolve(true);
      }, error => {
        this.commonSvc.showToast("", "Failed to fetch Config Firebase");
        resolve(false);
        throw error;
      });
    });
  }


  setUserId(userCode: string) {
    if (this.commonSvc.isMobile()) {
      this.firebaseX.setUserId(userCode);
    }
  }
  setScreenName(screenName: string) {
    if (this.commonSvc.isMobile()) {
      this.firebaseX.setScreenName(screenName);
    }
  }

  logEvent(eventName: string, eventProperties: any) {
    if (this.commonSvc.isMobile()) {
      this.firebaseX.logEvent(eventName, eventProperties);
    }
  }
  sendCrash() {
    this.firebaseX.sendCrash();
  }

  async getFCM(iTry: number) {
    if (iTry < 3) {
      await this.getFCMToken().then(async (token) => {
        if (!token) {
          let x = setInterval(() => {
            this.getFCM(iTry + 1);
            clearInterval(x);
          }, environment.remoteConfig[RemoteConfig.iFcmTry]);
        } else {
          await this.storage.get('ACCOUNT').then((account) => {
            if (account) {
              this.updateFirebase(account.userCode);
            }
          });
        }
      });
    }
  }

  getFCMToken(): Promise<string> {
    return new Promise((resolve) => {
      if (this.commonSvc.isAndroid()) {
        this.firebaseX
          .getToken()
          .then((token) => {
            console.log(`The token is ${token}`);
            this.subsFCMListerner();
            this.saveFirebaseX(token);
            resolve(token);
          }) // save the token server-side and use it to push notifications to this device
          .catch((error) => {
            resolve(null);
            console.error('Error getting token', error);
          });
      } else if (this.commonSvc.isIos()) {
        this.firebaseX
          .getAPNSToken()
          .then((token) => {
            console.log(`The token is ${token}`); // save the token server-side and use it to push notifications to this device
            this.subsFCMListerner();
            this.saveFirebaseX(token);
            resolve(token);
          })
          .catch((error) => {
            resolve(null);
            console.error('Error getting token', error);
          });
      } else {
        if (!firebase.apps.length) {
          firebase.initializeApp(environment.firebaseConfig);
        } else {
          firebase.app(); // if already initialized, use that one
        }

        // if (firebase.messaging.isSupported() && this.currentRoute.includes('otlre')) {
        // // if (!firebase.messaging.isSupported()) {
        //   this.showDialogUnsupportFb();
        //   resolve(null);
        // }

        if ('serviceWorker' in navigator) {
          navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then((registration) => {
              console.log(
                'Registration successful, scope is:',
                registration.scope
              );
              const messaging = firebase.messaging();
              messaging
                .getToken({
                  vapidKey: environment.remoteConfig[RemoteConfig.vapidKey],
                  serviceWorkerRegistration: registration,
                })
                .then((currentToken) => {
                  if (currentToken) {
                    console.log('current token for client: ', currentToken);
                    this.saveFirebaseX(currentToken);
                    resolve(currentToken);
                    // Track the token -> client mapping, by sending to backend server
                    // show on the UI that permission is secured
                  } else {
                    console.log(
                      'No registration token available. Request permission to generate one.'
                    );
                    resolve(null);

                    // shows on the UI that permission is required
                  }
                })
                .catch((err) => {
                  console.log(err);
                  resolve(null);
                });

              messaging.onMessage((payload) => {
                console.log('Message received. ', payload);
                if (payload.data.code === 'FORCE_LOGOUT') {
                  this.forceLogOut(payload.data);
                } else {
                  console.log(`User opened a notification ${payload.data}`);
                  this.commonSvc.showToast("alertH.newNotif", "alertM.newMsg");
                  this.messageSvc.pokeNewNotif(true);
                }
              });

            })
            .catch((err) => {
              throw err;
            });
        }

      }
    });
  }


  showDialogUnsupportFb() {
    this.langSvc
      .getWords(['trxFirebase.header', 'trxFirebase.message', 'trxFirebase.redirect'])
      .subscribe((words) => {
        this.alertCtrl
          .create({
            header: words['trxFirebase.header'],
            message: words['trxFirebase.message'],
            backdropDismiss: false,
            buttons: [
              {
                text: words['trxFirebase.redirect'],
                handler: () => {
                  if (isPlatform("ios")) {
                    //this.market.open('id1584306728');
                    window.open(environment.remoteConfig[RemoteConfig.appWebStoreLink], "_system", "location=yes");
                  } else {
                    window.open(environment.remoteConfig[RemoteConfig.playWebStoreLink], "_system");
                   // this.market.open('id.co.paninlife.connect');
                  }
                }
              }
            ],
          })
          .then((alertBox) => {
            alertBox.present();
          });
      });
  }

  subFCMTopicWeb(token: string): Observable<BaseRs> {
    const uid = this.commonSvc.getDeviceUUID();
    const req: FCMTopicRq = {
      tokens: [token],
    };
    return this.httpClientSvc.put<BaseRs>(environment.remoteConfig[RemoteConfig.uriPutSubTopic], uid, req);
  }

  unsubFCMTopicWeb(token: string): Observable<BaseRs> {
    const uid = this.commonSvc.getDeviceUUID();
    const req: FCMTopicRq = {
      tokens: [token],
    };
    return this.httpClientSvc.put<BaseRs>(
      environment.remoteConfig[RemoteConfig.uriPutUnsubTopic],
      uid,
      req
    );
  }

  subsFCMEvent() {
    if (this.commonSvc.isMobile()) {
      this.firebaseX
        .subscribe('developer')
        .then(() => this.saveFirebaseXTopic('developer')) // save the token server-side and use it to push notifications to this device
        .catch((error) =>
          this.saveFirebaseXTopic('error subsFCMEvent' + error)
        );
    } else {
      this.getFirebaseX().then((token) => {
        if (token) {
          this.subFCMTopicWeb(token).subscribe((rs) => {
            console.log(rs);
          });
        }
      });
    }
  }

  showForceLogOut(msg: any) {
    this.langSvc.getWords(['trxExit.exit']).subscribe((words) => {
      this.alertCtrl
        .create({
          header: msg.title,
          message: msg.body,
          backdropDismiss: false,
          buttons: [
            {
              text: words['trxExit.exit'],
              handler: () => {
                this.navCtrl.navigateRoot('/home-landing');
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    });
  }

  subsFCMListerner() {
    if (this.commonSvc.isMobile()) {
      this.firebaseX.onMessageReceived().subscribe((data) => {
        if (data.code === 'FORCE_LOGOUT') {
          this.forceLogOut(data);
        } else if (data.code === 'DEACTIVATE_ACCOUNT') {
          this.removeBiometric();
          this.storage.get('ACCOUNT').then((account) => {
            if (account) {
              this.forceLogOut(data);
            } else {
              this.navCtrl.navigateRoot('/home-landing');
            }
          });
        } else {
          console.log(`User opened a notification ${data}`);
          if (data.tap) {
            this.messageSvc.pokeNewNotif(false);
            const params = {
              id: data.idMsg,
            };
            this.navCtrl.navigateForward(["mail", params]);
          } else {
            this.messageSvc.pokeNewNotif(true);
          }
        }
      });
      this.firebaseX
        .onTokenRefresh()
        .subscribe((newtoken: string) => this.saveFirebaseX(newtoken));

    }
  }

  forceLogOut(data: any) {
    this.storage.get('ACCOUNT').then((account) => {
      if (account) {
        this.storage.remove('ACCOUNT');
        this.storage.remove('EXPDATE');
        this.storage.remove('NEWNOTIF');
        this.storage.remove('NOTIF');
        this.removeFCM();
        this.idle.stop();
        this.showForceLogOut(data);
      }
    });
  }

  removeBiometric() {
    this.storage.remove('CREDENTIAL');
    this.storage.remove('DISABLE_BIOMETRIC_REMINDER');
  }


  removeFCM() {
    this.saveFirebaseXTopic(null);
    if (this.commonSvc.isMobile()) {
      this.firebaseX
        .unsubscribe('developer')
        .then(() => console.log(`unsuscribed`)) // save the token server-side and use it to push notifications to this device
        .catch(() => console.log('Error unsuscribed'));
    } else {
      this.getFirebaseX().then((token) => {
        if (token) {
          this.unsubFCMTopicWeb(token).subscribe((rs) => {
            console.log(rs);
          });
        }
      });
    }
  }

  saveFirebaseX(firebaseXid: string) {
    this.storage.set('FirebaseX', firebaseXid);
  }
  getFirebaseX(): Promise<string> {
    return new Promise((resolve) => {
      this.storage.get('FirebaseX').then((token) => {
        if (token) {
          resolve(token);
        } else {
          this.getFCMToken().then((token) => {
            resolve(token);
          });
        }
      });
    });
  }

  saveFirebaseXTopic(firebaseXid: string) {
    this.storage.set('FirebaseXTopic', firebaseXid);
  }
  getFirebaseXTopic(): Promise<string> {
    return this.storage.get('FirebaseXTopic');
  }

  async updateFirebase(userCode: string): Promise<void> {
    const uid = this.commonSvc.getDeviceUUID();
    const model = this.commonSvc.getDevice().toString();
    await this.getFirebaseX().then((token) => {
      const userDeviceDTO: UserDeviceDTO = {
        deviceId: uid,
        tokenId: token,
        deviceType: model,
      };

      const req: DeviceTokenRq = {
        userCode: userCode,
        userDevice: userDeviceDTO,
      };

      this.httpClientSvc
        .put<LoginRs>(environment.remoteConfig[RemoteConfig.uriPutDeviceToken], uid, req)
        .subscribe(
          (rs) => {
            console.log(rs);
          },
          (err) => {
            this.firebaseX.logError(err.message, err);
            console.log(err);
          }
        );
    });
  }
}
