import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { LoginRq } from '../../interface/request/login-rq';
import { LoginRs } from '../../interface/response/login-rs';
import { HttpClientUtillService } from '../utill/http-client-utill.service';
import { environment } from 'src/environments/environment';
import { CommonUtillService } from '../utill/common-utill.service';
import { LogoutRq } from 'src/app/interface/request/logout-rq';
import { BaseRs } from 'src/app/interface/response/base-rs';
import { EncryptionUtillService } from '../utill/encryption-utill.service';
import { FireabasexService } from '../firebasex/fireabasex.service';
import { UserDeviceDTO } from 'src/app/interface/dto/user-device-dto';
import { RegisterRq } from 'src/app/interface/request/register-rq';
import { RegisterRs } from 'src/app/interface/response/register-rs';
import { Observable } from 'rxjs';
import { DisclaimerRq } from 'src/app/interface/request/disclaimer-rq';
import { Idle } from '@ng-idle/core';
import { RemoteConfig } from 'src/app/enums/remote-config.enum';
import { PortalSettingService } from '../portalsetting/portal-setting.service';
import { ReqCredentialRq } from 'src/app/interface/request/req-credential-rq';
import { ChallengeCredRs } from 'src/app/interface/response/challenge-cred-rs';
import { CredentialRq } from 'src/app/interface/request/credential-rq';
import { BiometricRq } from 'src/app/interface/request/biometric-rq';
import { TokenRs } from 'src/app/interface/response/token-rs';
import { TokenRq } from 'src/app/interface/request/token-rq';
import { LogLoginRq } from 'src/app/interface/request/log-login-rq';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentAccessToken: string = null;

  constructor(
    private idle: Idle,
    private httpClientSvc: HttpClientUtillService,
    private settingSvc: PortalSettingService,
    private localStorage: Storage,
    private commonSvc: CommonUtillService,
    private encryptSvc: EncryptionUtillService,
    private firebaseXSvc: FireabasexService
  ) {
    this.loadToken();
  }

  setAccessToken(token: string) {
    sessionStorage.setItem("ACCESS_TOKEN", token);
  }

  setRefreshToken(token: string) {
    sessionStorage.setItem("REFRESH_TOKEN", token);
  }

  getAccessToken(): string {
    return sessionStorage.getItem("ACCESS_TOKEN");
  }

  getRefreshToken(): string {
    return sessionStorage.getItem("REFRESH_TOKEN");
  }

  loadToken() {
    this.getAccount().then((account) => {
      if (account && account.accessToken) {
        this.currentAccessToken = account.accessToken;
      }
    })
  }

  refreshToken(token: string): Observable<TokenRs> {
    const uid = this.commonSvc.getDeviceUUID();
    const rq: TokenRq = {
      refreshToken: token
    }
    return this.httpClientSvc._post<TokenRs>(
      environment.remoteConfig[RemoteConfig.uriPostRefreshToken],
      uid,
      rq
    );
  }

  refreshJWTToken(): Observable<TokenRs> {
    const uid = this.commonSvc.getDeviceUUID();
    if (this.getRefreshToken()) {
      const rq: TokenRq = {
        refreshToken: this.getRefreshToken()
      }
      return this.httpClientSvc.post<TokenRs>(
        environment.remoteConfig[RemoteConfig.uriPostRefreshToken],
        uid,
        rq
      );
    }
  }

  refreshJToken(token: string): Observable<TokenRs> {
    const uid = this.commonSvc.getDeviceUUID();
    const rq: TokenRq = {
      refreshToken: token
    }
    return this.httpClientSvc.post<TokenRs>(
      environment.remoteConfig[RemoteConfig.uriPostRefreshToken],
      uid,
      rq
    );

  }

  register(
    policyNum: string,
    email: string,
    mobilePhone: string
  ): Observable<RegisterRs> {
    const uid = this.commonSvc.getDeviceUUID();
    const rq: RegisterRq = {
      email: email,
      mobilePhone: mobilePhone,
      policyNum: policyNum,
    };
    return this.httpClientSvc.post<RegisterRs>(
      environment.remoteConfig[RemoteConfig.uriPostRegister],
      uid,
      rq
    );
  }

  login(username: string, password: string, isPasswordless?: boolean): Promise<LoginRs> {
    return new Promise((resolve) => {
      this.commonSvc.createLoader().then((loader) => {
        loader.present().then(async () => {
          const uid = this.commonSvc.getDeviceUUID();
          let passEnc = "";
          let credName = "";
          let isWebAuthn = false;
          if (isPasswordless && !this.commonSvc.isMobile()) {
            passEnc = password;
            credName = "WEBAUTHN";
            isWebAuthn = true;
          } else {
            if (isPasswordless) {
              passEnc = password;
            } else {
              passEnc = this.encryptSvc.encryptAES(password, environment.remoteConfig[RemoteConfig.passwordSecretKey]);
            }
          }
          const model = this.commonSvc.getDevice().toString();
          this.firebaseXSvc.getFirebaseX().then((token) => {
            const userDeviceDTO: UserDeviceDTO = {
              deviceId: uid,
              tokenId: token,
              deviceType: model,
              isBiometric: isPasswordless
            };

            const loginRq: LoginRq = {
              userName: username,
              password: passEnc,
              userDevice: userDeviceDTO,
              credName: credName,
              isWebAuthn: isWebAuthn
            };
            //console.log(JSON.stringify(loginRq));
            this.httpClientSvc.post<LoginRs>(environment.remoteConfig[RemoteConfig.uriPostLogin], uid, loginRq)
              .subscribe((rs) => {
                if (rs.statusCode === '200') {
                  if (!rs.profile.profilePicture) {
                    rs.profile.profilePicture =
                      '../../../assets/icon/account/ac_profile.png';
                  } else {
                    rs.profile.profilePicture =
                      'data:image/jpeg;base64,' + rs.profile.profilePicture;
                  }
                  if (rs.roleName == "SU") {
                    rs.ccaCode = rs.userCode;
                  }
                  this.idle.watch();
                  this.firebaseXSvc.subsFCMEvent();
                  this.saveExpDate();
                  this.saveAccount(rs);
                  const events = { user: rs.userCode, method: rs.loginType };
                  this.firebaseXSvc.logEvent('login', events);
                  this.postLoginLog("LOGIN", rs)
                }
                resolve(rs);
              })
              .add(() => {
                loader.dismiss();
              });
          });
        });
      });
    });
  }

  logout(): Promise<BaseRs> {
    return new Promise((resolve) => {
      this.commonSvc.createLoader().then((loader) => {
        loader.present().then(() => {
          const uid = this.commonSvc.getDeviceUUID();
          this.getAccount().then((account) => {
            if (!account) {
              loader.dismiss();
              const rs: BaseRs = {
                statusCode: "200",
                message: ""
              }
              resolve(rs);
            } else {
              const logoutRq: LogoutRq = {
                userCode: account.roleName == "SU" ? account.ccaCode : account.userCode,
                deviceId: uid,
              };

              this.httpClientSvc.put<BaseRs>(environment.remoteConfig[RemoteConfig.uriPutLogout], uid, logoutRq)
                .subscribe((rs) => {
                  if (rs.statusCode === '200') {
                    this.idle.stop();
                    this.postLoginLog("LOGOUT", account)
                    this.removeAccount();
                  }
                  resolve(rs);
                }).add(() => {
                  loader.dismiss();
                });
            }
          });
        });
      });
    });
  }



  reqRegisterCred(username: string): Observable<ChallengeCredRs> {
    const uid = this.commonSvc.getDeviceUUID();
    const rq: ReqCredentialRq = {
      userName: username
    };
    return this.httpClientSvc.post<ChallengeCredRs>(environment.remoteConfig[RemoteConfig.uriPostReqRegisterCred], uid, rq);
  }


  registerCred(username: string, credential: string, credName: string): Observable<BaseRs> {
    const uid = this.commonSvc.getDeviceUUID();
    const rq: CredentialRq = {
      userName: username,
      credential: credential,
      credName: credName
    };
    return this.httpClientSvc.post<BaseRs>(environment.remoteConfig[RemoteConfig.uriPostRegisterCred], uid, rq);
  }

  reqLoginCred(username: string): Observable<ChallengeCredRs> {
    const uid = this.commonSvc.getDeviceUUID();
    const rq: ReqCredentialRq = {
      userName: username
    };
    return this.httpClientSvc.post<ChallengeCredRs>(environment.remoteConfig[RemoteConfig.uriPostReqLoginCred], uid, rq);
  }

  loginCred(username: string, credential: string, credName: string): Promise<LoginRs> {
    return new Promise((resolve) => {
      this.commonSvc.createLoader().then((loader) => {
        loader.present();
        const uid = this.commonSvc.getDeviceUUID();
        const model = this.commonSvc.getDevice().toString();
        this.firebaseXSvc.getFirebaseX().then((token) => {
          const userDeviceDTO: UserDeviceDTO = {
            deviceId: uid,
            tokenId: token,
            deviceType: model,
          };
          const rq: CredentialRq = {
            userName: username,
            credential: credential,
            credName: credName
          };
          this.httpClientSvc.post<BaseRs>(environment.remoteConfig[RemoteConfig.uriPostLoginCred], uid, rq);
        });
      });
    });
  }


  activationBiometric(username: string, isBiometric: boolean): Observable<BaseRs> {
    const uid = this.commonSvc.getDeviceUUID();
    const rq: BiometricRq = {
      userName: username,
      deviceId: uid
    };
    let uri = "";
    if (isBiometric) {
      uri = environment.remoteConfig[RemoteConfig.uriPutActivateBiometric]
    } else {
      uri = environment.remoteConfig[RemoteConfig.uriPutDeactivateBiometric]
    }
    return this.httpClientSvc.put<BaseRs>(uri, uid, rq);
  }


  saveCredential(username: string) {
    this.localStorage.set('CREDENTIAL', username);
  }



  removeCredential() {
    this.localStorage.remove('CREDENTIAL');
    this.getDisableBiometricReminder().then((data) => {
      if (data) {
        this.removeDisableBiometricReminder();
      }
    });
  }

  getCredential(): Promise<string> {
    return this.localStorage.get('CREDENTIAL');
  }

  saveDisableBiometricReminder(username: string) {
    console.log('save disable biometric', username);
    return this.localStorage.set('DISABLE_BIOMETRIC_REMINDER', username);
  }

  getDisableBiometricReminder() {
    return this.localStorage.get('DISABLE_BIOMETRIC_REMINDER');
  }

  removeDisableBiometricReminder() {
    this.localStorage.remove('DISABLE_BIOMETRIC_REMINDER');
  }

  saveAccount(account: LoginRs) {
    this.currentAccessToken = account.accessToken;
    this.setAccessToken(account.accessToken);
    this.setRefreshToken(account.refreshToken);
    this.localStorage.set('ACCOUNT', account);
  }

  removeAccount() {
    this.currentAccessToken = null;

    this.firebaseXSvc.removeFCM();
    sessionStorage.removeItem("REFRESH_TOKEN");
    sessionStorage.removeItem("ACCESS_TOKEN");
    this.localStorage.remove('ACCOUNT');
    this.localStorage.remove('EXPDATE');
    this.localStorage.remove('NEWNOTIF');
    this.localStorage.remove('NOTIF');

  }

  agreementTerm(userCode: string, isAgree: boolean): Observable<BaseRs> {
    const uid = this.commonSvc.getDeviceUUID();
    const rq: DisclaimerRq = {
      userCode: userCode,
      isAgree: isAgree,
    };
    return this.httpClientSvc.put<BaseRs>(environment.remoteConfig[RemoteConfig.uriPutDisclaimer], uid, rq);
  }

  getAccount(): Promise<LoginRs> {
    return this.localStorage.get('ACCOUNT');
  }

  checkFirstOpen() {
    return this.localStorage.get('ALREADY_TUTORIAL');
  }

  saveExpDate(timeout?: number) {
    if (typeof timeout !== 'undefined') {
      this.localStorage.set('EXPDATE', Date.now() + timeout * 1000);
    } else {
      this.settingSvc.getMobileSetting(
        environment.remoteConfig[RemoteConfig.timeoutCode].toString()).subscribe((rs) => {
          const seconds = Number.parseInt(rs.setting.valSetting);
          if (seconds > 0) {
            this.localStorage.set('EXPDATE', Date.now() + seconds * 1000);
          }
        });
    }
  }

  getExpDate(): Promise<number> {
    return this.localStorage.get('EXPDATE');
  }

  postLoginLog(type: string, acc : LoginRs): void {

    const uid = this.commonSvc.getDeviceUUID();

    const req: LogLoginRq = {
        userName: acc.userCode,
        email: acc.profile.email,
        roleName: acc.roleName,
        deviceType: this.commonSvc.getDevice().toString(),
        type: type,
        loginType: acc.loginType
    };
    this.httpClientSvc.post<BaseRs>(environment.remoteConfig[RemoteConfig.uriPostLogLogin], uid, req).subscribe((rs) => {
        console.log(rs);
    });
  }
}
