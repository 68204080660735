import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanLoad,
  Route,
  UrlSegment,
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private authSvc: AuthService, private navCtrl: NavController) { }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.authSvc.checkFirstOpen().then<any>((val) => {
      if (!val) {
        this.navCtrl.navigateRoot('/welcome');
        return false;
      } else {
        this.authSvc.getAccount().then((account) => {
          if (account) {
            if (account.roleName == "PH") {
              this.navCtrl.navigateRoot('/tabs');
            } else {
              this.navCtrl.navigateRoot('/admin');
            }
            return false;
          }
        });
      }
      return true;
    });
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authSvc.getAccount().then((account) => {
      // console.log(next.routeConfig.path);
      // console.log(state.url);
      if (!account) {
        this.navCtrl.navigateRoot('/home-landing');
        return false;
      } else {
        // let adminUris = environment.adminUri.split(",").find(x => state.url.includes(x));
        // let notifUris = environment.notifierUri.split(",").find(x => state.url.includes(x));
        // let allowUris = environment.allowUri.split(",").find(x => state.url.includes(x));
        // if (account.roleName == "PH" && (adminUris || notifUris)) {
        //   this.navCtrl.navigateForward('/tabs');
        //   return false;
        // } else if (account.roleName == "Administrators" && !adminUris && !allowUris) {
        //   this.navCtrl.navigateForward('/admin');
        //   return false;
        // } else if (account.roleName == "NOTIFIER" && !notifUris && !allowUris) {
        //   this.navCtrl.navigateForward('/admin');
        //   return false;
        // }

        if (account.menus) {
          const isValid = account.menus.find(x => next.routeConfig.path.includes(x));
          if (account.roleName == "PH" && !isValid) {
            this.navCtrl.navigateForward('/tabs');
            return false;
          } else if (!isValid) {
            this.navCtrl.navigateForward('/admin');
            return false;
          }
        } else {
          this.navCtrl.navigateRoot('/home-landing');
          return false;
        }
      }
      return true;
    });
  }
}
