import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Directive({
  selector: '[appCheckPermission]'
})
export class CheckPermissionDirective implements OnInit {

  @Input() appCheckPermission: string;

  constructor(private authSvc: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {

  }
  ngOnInit(): void {
    this.authSvc.getAccount().then(acc => {
      if (acc.permissions && acc.permissions.includes(this.appCheckPermission)) {
        this.viewContainer.createEmbeddedView(this.templateRef)
      } else {
        this.viewContainer.clear();
      }
    })
  }

}
