import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { PortalSettingRs } from 'src/app/interface/response/portal-setting-rs';
import { HttpClientUtillService } from '../utill/http-client-utill.service';
import { environment } from 'src/environments/environment';
import { CommonUtillService } from '../utill/common-utill.service';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { LangService } from '../lang/lang.service';
import * as semver from "semver";
import { RemoteConfig } from 'src/app/enums/remote-config.enum';

@Injectable({
  providedIn: 'root',
})
export class CheckVerService {

  constructor(
    private commonSvc: CommonUtillService,
    private alertCtrl: AlertController,
    private langSvc: LangService,
    private httpClientSvc: HttpClientUtillService,
    private market: Market
  ) {}

  checkNewVer() {
    const uid: string = this.commonSvc.getDeviceUUID();
    this.commonSvc.getAppVerNum().then((ver) => {
        if (semver.lt(ver,environment.remoteVer)) {
          this.langSvc
            .getWords(['trxNewVer.header', 'trxNewVer.message', 'trxNewVer.update', 'trxNewVer.later'])
            .subscribe((words) => {
              this.alertCtrl
                .create({
                  header: words['trxNewVer.header'],
                  backdropDismiss:false,
                  message: this.commonSvc.formatString(
                    words['trxNewVer.message'],
                    ver,
                    environment.remoteVer
                  ),
                  buttons: [
                    // {
                    //   text: words['trxNewVer.later'],
                    //   role:"cancel"
                    // },
                    {
                      text: words['trxNewVer.update'],
                      handler: () => {
                        if (this.commonSvc.isAndroid()) {
                          // window.open("https://play.google.com/store/apps/details?id=com.example.app&hl=en", "_system");
                          this.market.open(environment.remoteConfig[RemoteConfig.playStorePackage]);
                        } else if (this.commonSvc.isIos()) {
                          this.market.open(environment.remoteConfig[RemoteConfig.appStorePackage]);
                          // window.open("https://itunes.apple.com/id/app/go-passpot/id1234449206?mt=8", "_system","location=yes");
                        }
                      }
                    }
                  ],
                })
                .then((alertBox) => {
                  alertBox.present();
                });
            });
        }
      });
  }

}
