import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { RemoteConfig } from 'src/app/enums/remote-config.enum';
import { PortalSettingRs } from 'src/app/interface/response/portal-setting-rs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { LangService } from '../lang/lang.service';
import { PortalSettingService } from '../portalsetting/portal-setting.service';
import { CommonUtillService } from '../utill/common-utill.service';
import { HttpClientUtillService } from '../utill/http-client-utill.service';

@Injectable({ providedIn: 'root' })
export class IdleService {
  private seconds: number;

  constructor(
    private idle: Idle,
    private alertCtrl: AlertController,
    private settingSvc: PortalSettingService,
    private authSvc: AuthService,
    private langSvc: LangService,
    private navCtrl: NavController
  ) { }

  init() {
    this.settingSvc.getMobileSetting(environment.remoteConfig[RemoteConfig.timeoutCode].toString()).subscribe(rs => {
      if (rs.statusCode === "200") {
        this.seconds = Number.parseInt(rs.setting.valSetting);
        if (this.seconds > 0) {
          this.idle.setIdle(1);
          this.idle.setTimeout(this.seconds);
          this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
          this.checkTimeOut();
        }
      }
    });

    this.idle.onIdleEnd.subscribe(() => {
      this.authSvc.saveExpDate(this.seconds);
      this.watch();
    });

    this.idle.onTimeout.subscribe(() => {
      this.checkTimeOut();
    });

    this.idle.onIdleStart.subscribe(() => {


    });
  }

  timeOutLogout() {
    this.authSvc.logout().then(() => {
      this.langSvc
        .getWords(['trxSession.exit', 'trxSession.header', 'trxSession.msg'])
        .subscribe((words) => {
          this.alertCtrl
            .create({
              header: words['trxSession.header'],
              message: words['trxSession.msg'],
              backdropDismiss: false,
              buttons: [
                {
                  text: words['trxSession.exit'],
                  handler: () => {
                    this.navCtrl.navigateRoot('/home-landing');
                  },
                },
              ],
            })
            .then((alert) => {
              alert.present();
            });
        });
    });
  }

  checkTimeOut() {
    this.authSvc.getExpDate().then(val => {
      if (val) {
        if (val > Date.now()) {
          this.watch();
        } else {
          this.timeOutLogout();
        }
      }
    });
  }


  watch() {
    this.idle.watch();
  }

  stop() {
    this.idle.stop();
  }
}
