import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RemoteConfig } from 'src/app/enums/remote-config.enum';
import { PliasSettingsRs } from 'src/app/interface/response/plias-settings-rs';
import { PortalSettingRs } from 'src/app/interface/response/portal-setting-rs';
import { environment } from 'src/environments/environment';
import { CommonUtillService } from '../utill/common-utill.service';
import { HttpClientUtillService } from '../utill/http-client-utill.service';

@Injectable({
  providedIn: 'root'
})
export class PortalSettingService {

  constructor(private httpClientSvc: HttpClientUtillService,
    private commonSvc: CommonUtillService) { }



  getSourceFunds(): Observable<PliasSettingsRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.get<PliasSettingsRs>(
      environment.remoteConfig[RemoteConfig.uriGetSourceFunds], uid
    );
  }

  getCountries(): Observable<PliasSettingsRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.get<PliasSettingsRs>(
      environment.remoteConfig[RemoteConfig.uriGetCountries],
      uid
    );
  }

  getAddresTypes(): Observable<PliasSettingsRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.get<PliasSettingsRs>(
      environment.remoteConfig[RemoteConfig.uriGetAddressTypes],
      uid
    );
  }

  getTypeIds(): Observable<PliasSettingsRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.get<PliasSettingsRs>(
      environment.remoteConfig[RemoteConfig.uriGetTypeIds],
      uid
    );
  }

  getGenders(): Observable<PliasSettingsRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.get<PliasSettingsRs>(
      environment.remoteConfig[RemoteConfig.uriGetGenders],
      uid
    );
  }

  getBeneficiaryRules(): Observable<PliasSettingsRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.get<PliasSettingsRs>(
      environment.remoteConfig[RemoteConfig.uriGetBeneficiaryRules],
      uid
    );
  }

  getRelsByPolNum(policyNum:string): Observable<PliasSettingsRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.get<PliasSettingsRs>(
      this.commonSvc.formatString( environment.remoteConfig[RemoteConfig.uriGetRelsByPolNum],policyNum),
      uid
    );
  }


  getMobileSetting(code: string): Observable<PortalSettingRs> {
    const uid = this.commonSvc.getDeviceUUID();
    return this.httpClientSvc.get<PortalSettingRs>(this.commonSvc.formatString(
      environment.remoteConfig[RemoteConfig.uriGetSetting], code),
      uid
    );
  }


}
