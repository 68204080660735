import { ErrorHandler, Injectable } from '@angular/core';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { environment } from 'src/environments/environment';
import * as stacktrace from 'stacktrace-js';
import { RemoteConfig } from '../enums/remote-config.enum';
import { CommonUtillService } from '../services/utill/common-utill.service';

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler implements ErrorHandler {
  isErrorModalOpen: boolean = false;
  constructor(
    private firebase: FirebaseX,
    private commonSvc: CommonUtillService
  ) { }

  handleError(error: any): void {
    if (error.stack) {
      stacktrace.fromError(error).then((trace) => {
        if (this.commonSvc.isMobile()) {
          this.firebase.logError(error.message, trace);
        } else {
          console.error(error);
        }
        if (error.name === 'HttpErrorResponse' && !this.isErrorModalOpen) {
          this.isErrorModalOpen = true;
          this.commonSvc.showAlert('alertH.system', 'alertM.error-system', { params: [error.status] }).then(_ => {
            // intentionally left blank
          }).finally(() => {
            this.isErrorModalOpen = false;
          });
        }
      });
    } else {
      if (this.commonSvc.isMobile()) {
        this.firebase.logError(error.message, error);
      } else {
        console.error(error);
      }

      const whiteList = [
        environment.remoteConfig[RemoteConfig.uriPostLogPage],
        environment.remoteConfig[RemoteConfig.uriPostLogLogin],
        environment.remoteConfig[RemoteConfig.uriPostSubmitDocumentClaim],
        environment.remoteConfig[RemoteConfig.uriPostUploadBenefDoc],
      ];

      if (
        error.name === 'HttpErrorResponse' &&
        !whiteList.includes(error.url) &&
        !this.isErrorModalOpen
      ) {
        this.isErrorModalOpen = true;
        this.commonSvc.showAlert('alertH.system', 'alertM.error-system', { params: [error.status] }).then(_ => {
          // intentionally left blank
        }).finally(() => {
          this.isErrorModalOpen = false;
        });

        //this.commonSvc.showAlert('alertH.system', error.message);
      }
    }
  }
}
