import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Directive({
  selector: '[appCheckRole]'
})
export class CheckRoleDirective implements OnInit {

  @Input() appCheckRole: string[];

  constructor(private authSvc: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }


  ngOnInit(): void {
     this.authSvc.getAccount().then(acc => {
        if (acc.roleName && this.appCheckRole.includes(acc.roleName)) {
          this.viewContainer.createEmbeddedView(this.templateRef)
        } else {
          this.viewContainer.clear();
        }
      })
  }


}
