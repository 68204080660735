import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import localeId from '@angular/common/locales/id';
import { registerLocaleData } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  constructor(public translate: TranslateService, private storage: Storage) {}

  initDefaultLang() {
    registerLocaleData(localeId, 'id');
    this.translate.use('id');

    return new Promise((resolve) => {
      this.storage.get('lang').then((val) => {
        if (val != null) {
          resolve(this.translate.use(val));
        } else {
          this.storage.set('lang', 'id');
          resolve(this.translate.use('id'));
        }
      });
    });
  }

  setLang(lang) {
    this.storage.set('lang', lang);
    this.translate.use(lang);
  }

  getLang() {
    return this.storage.get('lang');
  }

  getAngularLang() {
    return this.translate.currentLang;
  }

  getWord(key: string): Observable<string> {
    return this.translate.get(key);
  }

  getWords(keys: string[]): Observable<{}> {
    return this.translate.get(keys);
  }
}
